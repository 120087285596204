import React from 'react';
import './Navbar.scss';
import { NavLink } from "react-router-dom";
import { ReactComponent as Logo } from '../../../Assets/images/logo-white.svg';
import { ReactComponent as ArrowDown } from '../../../Assets/images/icons/chevron-left.svg';
import { ReactComponent as TogglerIcon } from '../../../Assets/images/icons/bars.svg';
import { ReactComponent as TogglerIconClose } from '../../../Assets/images/icons/times.svg';
import { ReactComponent as Globe } from '../../../Assets/images/icons/globe.svg';
import * as Constants from '../../../Common/Constants';
import { ScrollTop } from '../../../Common/Utils';

const NavbarAr = () => {

    const handleLanguageChange = (language) => {
        const currentUrl = window.location.href;
        const domain = window.location.origin;

        if (language === 'EN') {
            // Check if "/ar/" exists in the URL
            if (currentUrl.includes("/ar/")) {
                // Remove "/ar/" from the current pathname
                const newPathname = window.location.pathname.replace("/ar/", "/");
                const newUrl = `${domain}${newPathname}`;
                window.location.href = newUrl; // Redirect to the new URL
            }
        } else if (language === 'AR') {
            // Check if "/ar/" is already in the URL to avoid appending multiple times
            if (!currentUrl.includes("/ar/")) {
                // Append "/ar/" after the domain
                const newUrl = `${domain}/ar${window.location.pathname}`;
                window.location.href = newUrl; // Redirect to the new URL
            }
        }
    };

    function closePopupMenuInMobile() {
        if (document.getElementById('NavBtnClose') != null) {
            var IsMobileMenuOpen = document.getElementById('NavBtnClose').getAttribute('aria-expanded');
            if (IsMobileMenuOpen !== null && IsMobileMenuOpen === 'true') {
                document.getElementById('NavBtnClose').click();
            }
        }
    }

    return (
        <nav className="aug-navbar navbar navbar-expand-lg navbar-light" dir="rtl">
            <div className="container">
                <NavLink to="/ar/" onClick={ScrollTop} className="navbar-brand">
                    <Logo height="25" width="155" />
                </NavLink>
                <button id='NavBtnClose' className="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <TogglerIcon height={18} width={32} className="aug-icon-open" />
                    <TogglerIconClose height={18} width={32} className="aug-icon-close" />
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <ul className="navbar-nav align-items-lg-center me-auto pe-0">
                        <li className="nav-item dropdown">
                            <NavLink className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">المنتجات <ArrowDown className='mx-1' /></NavLink>
                            <ul className="dropdown-menu dropdown-menu-white">
                                <li>
                                    <NavLink to="/ar/omni" onClick={ScrollTop} className="nav-link">Omni</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/ar/spectra" onClick={ScrollTop} className="nav-link">Spectra</NavLink>
                                </li>

                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <NavLink className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">الشركة <ArrowDown className='mx-1' /> </NavLink>
                            <ul dir="rtl" className="dropdown-menu dropdown-menu-white">
                                <li>
                                    <NavLink to="/ar/about-us" onClick={ScrollTop} className="nav-link text-end">معلومات عنا</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/ar/careers" onClick={ScrollTop} className="nav-link text-end">وظائف</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/ar/contact-us" onClick={ScrollTop} className="nav-link text-end">اتصل بنا </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <NavLink className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">الموارد <ArrowDown className='mx-1' /> </NavLink>
                            <ul dir="rtl" className="dropdown-menu dropdown-menu-white">
                                <li>
                                    <a href={Constants.SITE_URL + "resources/blog"} onClick={closePopupMenuInMobile} target="_blank" rel="noreferrer" className="nav-link text-end">مدونة</a>
                                </li>

                                <li>
                                    <a href={Constants.SITE_URL + "resources/case-studies"} onClick={closePopupMenuInMobile} target="_blank" rel="noreferrer" className="nav-link text-end">دراسة الحالة </a>
                                </li>
                                <li>
                                    <a href={Constants.SITE_URL + "resources/newsroom"} onClick={closePopupMenuInMobile} target="_blank" rel="noreferrer" className="nav-link text-end">غرفة الأخبار</a>
                                </li>
                                <li>
                                    <a href={Constants.SITE_URL + "resources/faq"} onClick={closePopupMenuInMobile} target="_blank" rel="noreferrer" className="nav-link text-end">الأسئلة الشائعة</a>
                                </li>
                                {/* <li>
                                    <a href={Constants.SITE_URL + "resources/docs"} onClick={closePopupMenuInMobile} target="_blank" rel="noreferrer" className="nav-link text-end">التوثيق</a>
                                </li>
                                <li>
                                    <a href={Constants.SITE_URL + "resources/videos"} onClick={closePopupMenuInMobile} target="_blank" rel="noreferrer" className="nav-link text-end">فيديوهات</a>
                                </li> */}
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <NavLink className="nav-link d-flex align-items-center dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <Globe className='ms-2' />Ar</NavLink>
                            <ul className="dropdown-menu dropdown-menu-white">
                                <li>
                                    <button onClick={() => handleLanguageChange('EN')} className="nav-link">English</button>
                                </li>
                                <li>
                                    <button onClick={() => handleLanguageChange('AR')} className="nav-link active">Arabic</button>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item position-relative">
                            <span className='aug-nav-item-border'></span>
                        </li>
                        <li className="nav-item">
                            <a href={Constants.USERCONSOLE_DOMAIN} onClick={closePopupMenuInMobile} className="btn btn-outline-white">
                                تسجيل الدخول
                            </a>
                        </li>
                        <li className="nav-item me-lg-3" onClick={ScrollTop}>
                            <NavLink to="/ar/contact-sales" className='btn btn-primary' onClick={closePopupMenuInMobile}>     طلب عرض توضيحي
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default NavbarAr;