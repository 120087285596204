import React from 'react';
import { ReactComponent as LanguageIcone } from '../../../Assets/images/icons/language-outline.svg';

const GlobalLanguageContentAr = () => {

    return (
        <div dir='rtl' className='aug-empowering-clinicians__accordion-box'>
            <h3 className="aug-empowering-clinicians__accordion-box__title">
                <LanguageIcone height={48} width={48} /> دعم اللغة العالمية
            </h3>
            <div className="aug-empowering-clinicians__accordion-box__text">
            يُسهّل Omni التقاط المحادثة السريرية بأكثر من 37 لغة. إنه لا يعتمد على اللهجة ولديه قدرات مدمجة للتعرف على الكلام متعدد اللغات، مما يضمن التفاعل الطبيعي بين الأطباء والمرضى.
            </div>
        </div>
    );
};

export default GlobalLanguageContentAr;