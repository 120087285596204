import React from 'react';
import { ReactComponent as LanguageIcone } from '../../../Assets/images/icons/language-outline.svg';

const GlobalLanguageContent = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-box'>
            <h3 className="aug-empowering-clinicians__accordion-box__title">
                <LanguageIcone height={48} width={48} /> Global Language Support
            </h3>
            <div className="aug-empowering-clinicians__accordion-box__text">
            Omni facilitates clinical conversation capture in over 37 languages. It is accent agnostic and has built-in capabilities for multilingual speech recognition, ensuring natural clinician-patient interactions.
            </div>
        </div>
    );
};

export default GlobalLanguageContent;