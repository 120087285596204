import React from 'react';
import './AlertDialog.scss';
import Close from '../../../Assets/images/icons/times.svg';

const AlertDialog = () => {
    return (
       <section className='alert p-0 m-0 border-0' role="alert">
            <div className='aug-alert-dialog text-md-center d-md-flex align-items-md-center justify-content-md-between'>
                <div className='m-md-auto'>
                    Meet the Augnito Team at <a href='https://www.arabhealthonline.com/en/Home.html' target="_blank" rel="noreferrer">Arab Health 2025</a>, 
                    27-30 January, Dubai World Trade Centre
                </div>
                <div>
                    <img src={Close} alt="close" className='aug-alert-close' data-bs-dismiss="alert" aria-label="Close" /> 
                </div>
            </div>
       </section>
    );
};

export default AlertDialog;