import React from 'react';
import './KekaCareer.scss';

const KekaCareer = () => {
    return (
        <section className='aug-career-keka'>
            <div className='container'>
                <h2 className='aug-career__title mb-3'>Join Augnito Today</h2>
                <p>We are a dynamic team, tackling challenges with energy and adaptability. We are always looking for creative and spirited people to be a part of our mission to augment clinicians with the most optimal AI. While we’re deeply committed to our objectives, we also prioritize moments of joy and help one another grow comprehensively.</p>
                <a target="_blank" rel="noreferrer" className="btn btn-primary" href="https://augnito.keka.com/careers">View Open Positions</a>

                
                {/* <iframe title="kekaFrame" src="https://augnito.kekahire.com/api/embedjobs/37be0e0a-5863-4fdc-a569-1738d6669d42" id="kekaCareerRMS" onLoad={function () { }}></iframe> */}
            </div>
        </section>
    );
};


// var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
// var eventer = window[eventMethod];
// var messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";
// var HeightSet = false;
// eventer(messageEvent, function (e) {
//     // If the message is a resize frame request
    
//     if (e.data && typeof e.data === "string" && e.origin && !HeightSet) {
//         if (e.origin.indexOf("augnito.keka.com") !== -1 && e.data.indexOf('kekaHireCareerPortalHeight') !== -1) {
//             var kekaData = JSON.parse(e.data);
//             var kekaCareerRMS = document.getElementById("kekaCareerRMS");
//             kekaCareerRMS.style.height = (kekaData.kekaHireCareerPortalHeight - 120) + 'px';
//             if (kekaData.kekaHireCareerPortalHeight > 500) {
//                 HeightSet = true;
//             }
//         }
//     }
// }, false);

export default KekaCareer;
