import React from 'react';
import './OmniBanner.scss'
import OmniVideoAr from '../../../Assets/images/Omni/omni-banner.webm';
import OmniVideoArMp from '../../../Assets/images/Omni/omni-banner.mp4';
import {ReactComponent as PattrenIcone} from '../../../Assets/images/icons/pattren-icone.svg';
import { NavLink } from 'react-router-dom';

const OmniBannerAr = () => {
    return (
        <section dir='rtl' className='aug-omni-banner aug-spectra-banner'>
            <div className='container'>
                <div className="row gx-40">
                    <div className='col-md-12 col-lg-6'>
                        <div className='aug-spectra-banner-content'>
                            <h6 className='mb-2'><PattrenIcone className='ms-2'/> Omni</h6>
                            <h1 className='mb-3'>Omni AI Scribe من أجل تحسين التوثيق </h1>
                            <p>
                            مدعوم بالذكاء السريري البيئي. عزز التوثيق السريري من خلال التقاط الحوار الطبي متعدد اللغات وتنظيم البيانات بشكل فعال من أجل تكامل سلس في السجلات الطبية الإلكترونية.</p>
                            <NavLink to="/ar/contact-sales" className='btn btn-primary'>اطلب عرضًا توضيحيًا</NavLink>
                        </div>
                    </div>
                    <div className='col-md-12 col-lg-6'>
                        <div className='aug-spectra-video'>
                            <div className="">
                                <video width="100%" id='videoOmni' playsInline muted autoPlay="autoPlay" preload="metadata" loading="lazy" loop="loop">
                                    <source src={OmniVideoAr} type="video/webm" />
                                    <source src={OmniVideoArMp} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default OmniBannerAr;