import React from 'react';
import './Navbar.scss';
import { NavLink } from "react-router-dom";
import { ReactComponent as Logo } from '../../../Assets/images/logo-white.svg';
import { ReactComponent as ArrowDown } from '../../../Assets/images/icons/chevron-left.svg';
import { ReactComponent as TogglerIcon } from '../../../Assets/images/icons/bars.svg';
import { ReactComponent as TogglerIconClose } from '../../../Assets/images/icons/times.svg';
import { ReactComponent as Globe } from '../../../Assets/images/icons/globe.svg';
import * as Constants from '../../../Common/Constants';
import { ScrollTop } from '../../../Common/Utils';
import BaseComponent from '../../../Common/BaseComponent';

export default class Navbar extends BaseComponent {

    render() {

        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        const handleLanguageChange = (language) => {
            const currentUrl = window.location.href;
            const domain = window.location.origin;

            if (language === 'EN') {
                // Check if "/ar/" exists in the URL
                if (currentUrl.includes("/ar/")) {
                    // Remove "/ar/" from the current pathname
                    const newPathname = window.location.pathname.replace("/ar/", "/");
                    const newUrl = `${domain}${newPathname}`;
                    window.location.href = newUrl; // Redirect to the new URL
                }
            } else if (language === 'AR') {
                // Check if "/ar/" is already in the URL to avoid appending multiple times
                if (!currentUrl.includes("/ar/")) {
                    // Append "/ar/" after the domain
                    const newUrl = `${domain}/ar${window.location.pathname}`;
                    window.location.href = newUrl; // Redirect to the new URL
                }
            }
        };

        function closePopupMenuInMobile() {
            if (document.getElementById('NavBtnClose') != null) {
                var IsMobileMenuOpen = document.getElementById('NavBtnClose').getAttribute('aria-expanded');
                if (IsMobileMenuOpen !== null && IsMobileMenuOpen === 'true') {
                    document.getElementById('NavBtnClose').click();
                }
            }
        }

        return (
            <nav className="aug-navbar navbar navbar-expand-lg navbar-light">
                <div className="container">
                    <NavLink to="/" onClick={ScrollTop} className="navbar-brand">
                        <Logo height="25" width="155" />
                    </NavLink>
                    <button id='NavBtnClose' className="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <TogglerIcon height={18} width={32} className="aug-icon-open" />
                        <TogglerIconClose height={18} width={32} className="aug-icon-close" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav align-items-lg-center ms-auto">
                            <li className="nav-item dropdown">
                                <NavLink className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Product <ArrowDown className='mx-1' /></NavLink>
                                <ul className="dropdown-menu dropdown-menu-white">
                                    <li>
                                        <NavLink to="/omni" onClick={ScrollTop} className="nav-link">Omni</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/spectra" onClick={ScrollTop} className="nav-link">Spectra</NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <NavLink className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Company <ArrowDown className='mx-1' /> </NavLink>
                                <ul className="dropdown-menu dropdown-menu-white">
                                    <li>
                                        <NavLink to="/about-us" onClick={ScrollTop} className="nav-link">About us</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/careers" onClick={ScrollTop} className="nav-link">Careers</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/contact-us" onClick={ScrollTop} className="nav-link">Contact us</NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <NavLink className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Resources <ArrowDown className='mx-1' /> </NavLink>
                                <ul className="dropdown-menu dropdown-menu-white">
                                    <li>
                                        <a href={Constants.SITE_URL + "resources/blog"} onClick={closePopupMenuInMobile} target="_blank" rel="noreferrer" className="nav-link">Blog</a>
                                    </li>

                                    <li>
                                        <a href={Constants.SITE_URL + "resources/case-studies"} onClick={closePopupMenuInMobile} target="_blank" rel="noreferrer" className="nav-link">Case studies</a>
                                    </li>
                                    <li>
                                        <a href={Constants.SITE_URL + "resources/newsroom"} onClick={closePopupMenuInMobile} target="_blank" rel="noreferrer" className="nav-link">Newsroom</a>
                                    </li>
                                    <li>
                                        <a href={Constants.SITE_URL + "resources/faq"} onClick={closePopupMenuInMobile} target="_blank" rel="noreferrer" className="nav-link">FAQs</a>
                                    </li>
                                    {/* <li>
                                        <a href={Constants.SITE_URL + "resources/docs"} onClick={closePopupMenuInMobile} target="_blank" rel="noreferrer" className="nav-link">Documentation</a>
                                    </li>
                                    <li>
                                        <a href={Constants.SITE_URL + "resources/videos"} onClick={closePopupMenuInMobile} target="_blank" rel="noreferrer" className="nav-link">Videos</a>
                                    </li> */}
                                </ul>
                            </li>
                            {
                                regionData === "ME" ?
                                    <li className="nav-item dropdown">
                                        <NavLink className="nav-link d-flex align-items-center dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                            <Globe className='me-2' />En</NavLink>
                                        <ul className="dropdown-menu dropdown-menu-white">
                                            <li>
                                                <button onClick={() => handleLanguageChange('EN')} className="nav-link active">English</button>
                                            </li>
                                            <li>
                                                <button onClick={() => handleLanguageChange('AR')} className="nav-link">Arabic</button>
                                            </li>
                                        </ul>
                                    </li>
                                    :
                                    <></>
                            }
                            <li className="nav-item position-relative">
                                <span className='aug-nav-item-border'></span>
                            </li>
                            <li className="nav-item">
                                <a href={Constants.USERCONSOLE_DOMAIN} onClick={closePopupMenuInMobile} className="btn btn-outline-white">
                                    Login
                                </a>
                            </li>
                            <li className="nav-item ms-lg-3" onClick={ScrollTop}>
                                <NavLink to="/contact-sales" className='btn btn-primary' onClick={closePopupMenuInMobile}>Request a Demo</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}

