import React from 'react';
import { ReactComponent as Linkedin } from '../../../Assets/images/icons/linkedin.svg';

const AdvisoryBoard = () => {
    return (
        <section className='aug-leadership-team'>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-xl-11 col-12'>
                        <h2 className="aug-leadership-team__title">Advisory Board & Directors</h2>
                        <div className='row gx-80'>
                            <div className='col-12 col-sm-6 col-md-4'>
                                <div className='aug-leadership-team-item'>
                                    <div className='aug-leadership-team-img'>
                                        <img loading="lazy" src={require('../../../Assets/images/AboutUs/advisory-board/akbar.webp')} alt="Akbar , Member of Advisory Board at Augnito" className="img-fluid" />
                                    </div>
                                    <h3 className='aug-leadership-team-name mb-0'>Akbar Khan </h3>
                                    <a href='https://www.linkedin.com/in/akbar-khan-a0047341/' target="_blank" rel="noreferrer">
                                        <Linkedin />
                                    </a>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-4'>
                                <div className='aug-leadership-team-item'>
                                    <div className='aug-leadership-team-img'>
                                        <img loading="lazy" src={require('../../../Assets/images/AboutUs/advisory-board/ishaat-hussain.webp')} alt="Ishaat Hussain , Member of Advisory Board at Augnito" className="img-fluid" />
                                    </div>
                                    <h3 className='aug-leadership-team-name mb-0'>Ishaat Hussain </h3>
                                    <a href='https://www.linkedin.com/in/ishaat-hussain-72b18b25/' target="_blank" rel="noreferrer">
                                        <Linkedin />
                                    </a>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-4'>
                                <div className='aug-leadership-team-item mb-sm-0'>
                                    <div className='aug-leadership-team-img'>
                                        <img loading="lazy" src={require('../../../Assets/images/AboutUs/advisory-board/zinia-lawyer.webp')} alt="Zinia Lawyer , Member of Advisory Board at Augnito" className="img-fluid" />
                                    </div>
                                    <h3 className='aug-leadership-team-name mb-0'>Zinia Lawyer </h3>
                                    <a href='https://www.linkedin.com/in/zinia-lawyer-aabb94a7/' target="_blank" rel="noreferrer">
                                        <Linkedin />
                                    </a>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-4'>
                                <div className='aug-leadership-team-item mb-sm-0'>
                                    <div className='aug-leadership-team-img'>
                                        <img loading="lazy" src={require('../../../Assets/images/AboutUs/advisory-board/jitendra.webp')} alt="Jitendar , Member of Advisory Board at Augnito" className="img-fluid" />
                                    </div>
                                    <h3 className='aug-leadership-team-name mb-0'>Jitender Balakrishnan </h3>
                                    <a href='https://www.linkedin.com/in/jitender-balakrishnan-5954a432/' target="_blank" rel="noreferrer">
                                        <Linkedin />
                                    </a>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-4'>
                                <div className='aug-leadership-team-item mb-0'>
                                    <div className='aug-leadership-team-img'>
                                        <img loading="lazy" src={require('../../../Assets/images/AboutUs/advisory-board/maciej-kraus.webp')} alt="Maciej Kraus, Member of Advisory Board at Augnito" className="img-fluid" />
                                    </div>
                                    <h3 className='aug-leadership-team-name mb-0'>Maciej Kraus</h3>
                                    <a href='https://www.linkedin.com/in/maciejkraus/' target="_blank" rel="noreferrer">
                                        <Linkedin />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AdvisoryBoard;