import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { ReactComponent as Star } from '../../../Assets/images/icons/star-single.svg';
import './ScheduleDemo.scss';
import Validation from './Validation';
import axios from "axios";
import * as Constants from '../../../Common/Constants';
import CountryResponse from './country'

const ScheduleDemo = () => {
    const navigate = useNavigate();
    const [formERR, setFormERR] = useState(null);
    const initialFormState = {
        FirstName: '',
        LastName: '',
        Email: '',
        Phone: '',
        Country: '',
        CountryId: '0',
        Product: '',
        ProductId: '0',
        Company: '',
        JobTitle: '',
        SetupType: '',
        SetupTypeId: '0',
        AgreeToPrivacy: false
    };
    const [values, setValues] = useState(initialFormState);
    const [countries, setCountries] = useState(null);

    const getCountryList = async () => {
        var response = CountryResponse;
        //start loader

        if (response !== null &&  response.Data !== null && response.Data.Countries !== null) {

            response.Data.Countries.sort((a, b) => a.CountryName.localeCompare(b.CountryName));
            setCountries(response.Data.Countries);
        }
    };

    const handleChange = (e) => {
        if (e.target.name === "Country") {
            setValues({ ...values, ["CountryId"]: e.target.value, [e.target.name]: e.target.selectedOptions[0].text });
        } else if (e.target.name === "Product") {
            setValues({ ...values, ["ProductId"]: e.target.value, [e.target.name]: e.target.selectedOptions[0].text });
        } else if (e.target.name === "SetupType") {
            setValues({ ...values, ["SetupTypeId"]: e.target.value, [e.target.name]: e.target.selectedOptions[0].text });
        } else if (e.target.name === "AgreeToPrivacy") {
            setValues({ ...values, [e.target.name]: e.target.checked });
        } else {
            setValues({ ...values, [e.target.name]: e.target.value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormERR(null);
        const _err = Validation(values);
        console.log(_err);
        if (Object.keys(_err).length === 0) {
            //start loader
            try {
                console.log(values);
                var requestJson = {
                    "Request": {
                        ...values
                    }
                }
                //Call Zoho API to push data
                axios.post(Constants.SAVE_DEMO_REQUEST, requestJson).then(response => {
                    if(response != null && response.data !== null && response.data.Data !== null && response.data.Status === 0) {
                        navigate("/thank-you");
                    } else {
                        setFormERR(response.data.ErrorMessage);
                    }
                }).catch((error) => {
                    console.log("Failed", error);
                }).finally(() => {
                    //stop loader
                });
            } catch (error) {
                console.log('Failed:', error);
            } finally {
                //stop loader
                // Reset formData to initial state
                setValues(initialFormState);
            }
        } else {
            setFormERR("Kindly ensure all required fields are completed with valid and accurate information.");
        }
    };

    useEffect(() => {
        getCountryList();
    }, []);

    return (
        <div className="container">
            <section className='aug-schedule-demo'>
                <div className="row">
                    <div className="col-lg-5 col-md-12">
                        <h3 className="aug-schedule-demo__title">What to expect:</h3>
                        <ul className='mb-0 list-unstyled aug-schedule-demo__list'>
                            <li className='d-flex'><Star className='me-2' /> Experience an expert-led demo of our medical voice AI software</li>
                            <li className='d-flex'><Star className='me-2' />Commitment-free consultation on Augnito subscription options</li>
                            <li className='d-flex'><Star className='me-2' />Explore our robust product capabilities and benefits</li>
                            <li className='d-flex mb-0'><Star className='me-2' />Explore customized pricing for enterprise</li>
                        </ul>
                    </div>
                    <div className="col-md-12 col-lg-7">
                        <div className='aug-schedule-demo__form'>
                            <h3 className="aug-schedule-demo__title">Schedule a Demo</h3>
                            {formERR && <div className='aug-schedule-demo__error'> {formERR} </div>}
                            <form onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className='col-md-6 col-12'>
                                        <div className="mb-4">
                                            <input type="text" className="form-control" placeholder="First Name" id="FirstName" name="FirstName" value={values.FirstName} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-12'>
                                        <div className="mb-4">
                                            <input type="text" className="form-control" placeholder="Last name" id="LastName" name="LastName" value={values.LastName} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-12'>
                                        <div className="mb-4">
                                            <input type="email" className="form-control" placeholder="Email" id="Email" name="Email" value={values.Email} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-12'>
                                        <div className="mb-4">
                                            <input type="number" className="form-control" placeholder="Phone" id="Phone" name="Phone" value={values.Phone} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-12'>
                                        <div className="mb-4">
                                            <select id="Country" name="Country" value={values.CountryId} className="form-select form-control" onChange={handleChange}>
                                                <option value='0' defaultValue>Country</option>
                                                {countries !== null ? countries.map((country) => (
                                                    <option value={country.Id} key={country.Id}>
                                                        {country.CountryName}
                                                    </option>
                                                )) : ""
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-12'>
                                        <div className="mb-4">
                                            <select id="Product" name='Product' value={values.ProductId} className="form-select form-control" onChange={handleChange}>
                                                <option value="0" defaultValue>Select product</option>
                                                <option value="2">Omni</option>
                                                <option value="1">Spectra</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-12'>
                                        <div className="mb-4">
                                            <input type="text" className="form-control" placeholder="Company name" id="Company" name="Company" value={values.Company} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-12'>
                                        <div className="mb-4">
                                            <input type="text" className="form-control" placeholder="Job title" id="JobTitle" name="JobTitle" value={values.JobTitle} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="mb-4">
                                            <select id="SetupType" name='SetupType' value={values.SetupTypeId} className="form-select form-control" onChange={handleChange}>
                                                <option value="0" defaultValue>Setup type</option>
                                                <option value="1">Individual</option>
                                                <option value="2">Diagnostic/Small Clinic</option>
                                                <option value="3">Hospital</option>
                                                <option value="4">Software Company</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="AgreeToPrivacy" name='AgreeToPrivacy' checked={values.AgreeToPrivacy} onChange={handleChange} />
                                            <label className="form-check-label ms-1" htmlFor="exampleCheck1">By submitting this form, I acknowledge receipt of Augnito's Privacy Policy. *</label>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <button type="submit" className="btn btn-primary w-100">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ScheduleDemo;